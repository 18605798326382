import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SplashScreen } from '@capacitor/splash-screen';


import { lastValueFrom,  } from 'rxjs';
import { switchMap,  tap } from 'rxjs/operators';
import { SacFacade } from './sac.facade';
import { SacActions } from './sac.actions';
import { SacService } from '@shared/services/sac/sac.service';
import { Router } from '@angular/router';
import { AlertService } from '@shared/services/alert/alert.service';

@Injectable()
export class SacEffects {

  readonly getSac$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SacActions.getSac),
    tap(() => this.sacFacade.setLoading(true)),
    switchMap(async () => {
      try {
        const sacInfo = await lastValueFrom(
          this.sacService.getSac()
        );
        if(sacInfo.status === 'success'){
          return SacActions.getSacSuccess({ sacInfo });
        }else{
          return SacActions.getSacFail();
        }
      } catch (e) {
        console.error(e);
        return SacActions.getSacFail();
      }
    }),
    tap(() => this.sacFacade.setLoading(false))
  )
);

readonly getProjectUnit$ = createEffect(() =>
this.actions$.pipe(
  ofType(SacActions.getProjectUnit),
  tap(() => this.sacFacade.setLoading(true)),
  switchMap(async () => {
    try {
      const sacFormList = await lastValueFrom(
        this.sacService.getProjectUnit()
      );
      if(sacFormList.status === 'success'){
        return SacActions.getProjectUnitSuccess({ sacFormList  });
      }else{
        return SacActions.getProjectUnitFail();
      }
    } catch (e) {
      console.error(e);
      return SacActions.getProjectUnitFail();
    }
  }),
  tap(() => this.sacFacade.setLoading(false))
)
);

readonly getStatesSac$ = createEffect(() =>
this.actions$.pipe(
  ofType(SacActions.getStatesSac),
  tap(() => this.sacFacade.setLoadingState(true)),
  switchMap(async () => {
    try {
      const sacStates = await lastValueFrom(
        this.sacService.getStatesSac()
      );
      if(sacStates.status === 'success'){
        return SacActions.getStatesSacSuccess({ sacStates  });
      }else{
        return SacActions.getStatesSacFail();
      }
    } catch (e) {
      console.error(e);
      return SacActions.getStatesSacFail();
    }
  }),
  tap(() => this.sacFacade.setLoadingState(false))
)
);

readonly getAttetionsSac$ = createEffect(() =>
this.actions$.pipe(
  ofType(SacActions.getAttetionsSac),
  tap(() => this.sacFacade.setLoading(true)),
  switchMap(async () => {
    try {
      const sacAttetions = await lastValueFrom(
        this.sacService.getAttetionsSac()
      );
      if(sacAttetions.status === 'success'){
        return SacActions.getAttetionsSacSuccess({ sacAttetions  });
      }else{
        return SacActions.getAttetionsSacFail();
      }
    } catch (e) {
      console.error(e);
      return SacActions.getAttetionsSacFail();
    }
  }),
  tap(() => this.sacFacade.setLoading(false))
)
);

readonly createAttetions$ = createEffect(() =>
this.actions$.pipe(
  ofType(SacActions.createAttetions),
  tap(() => this.sacFacade.setLoading(true)),
  switchMap(async ({dataFormSac}) => {
    try {
      const newAttentionData = await lastValueFrom(
        this.sacService.createAttetions(dataFormSac)
      );
      const newAttention = {
        ...newAttentionData,
        unitName:dataFormSac.unitName
      };
      if(newAttention.status === 'success'){
        return SacActions.createAttetionsSuccess({ newAttention  });
      }else{
        return SacActions.createAttetionsFail();
      }
    } catch (e) {
      console.error(e);
      return SacActions.createAttetionsFail();
    }
  }),
  tap(() => this.sacFacade.setLoading(false))
));
readonly createAttetionsSuccess$ = createEffect(
  () =>
    this.actions$.pipe(
      ofType(SacActions.createAttetionsSuccess),
      tap(() => {
        this.router.navigate(['/sac-gracias']);
      })
    ),
  { dispatch: false }
);

readonly createNote$ = createEffect(() =>
this.actions$.pipe(
  ofType(SacActions.createNote),
  tap(() => this.sacFacade.setLoading(true)),
  switchMap(async ({createNote}) => {
    try {
      const newNote = await lastValueFrom(
        this.sacService.createNote(createNote)
      );
      if(newNote.status === 'success'){
        return SacActions.createNoteSuccess({ newNote  });
      }else{
        const dataMessage = {
          header:newNote.status,
          message:newNote.message,
        };
        this.alertService.presentAlert(dataMessage);
        return SacActions.createNoteFail();
      }
    } catch (e) {
      console.error(e);
      return SacActions.createNoteFail();
    }
  }),
  tap(() => this.sacFacade.setLoading(false))
));

readonly createNoteSuccess$ = createEffect(
  () =>
    this.actions$.pipe(
      ofType(SacActions.createNoteSuccess),
      tap(({ newNote }) => {
        const dataMessage = {
          header:newNote.status,
          message:'Nota creada con éxito'
        };
        this.alertService.presentToast(dataMessage);
        this.router.navigate(['/sac-historial']);
      })
    ),
  { dispatch: false }
);


readonly getDetailAttention$ = createEffect(() =>
this.actions$.pipe(
  ofType(SacActions.getDetailAttention),
  tap(() => this.sacFacade.setLoading(true)),
  switchMap(async ({idAttetion}) => {
    try {
      const detailAttetion = await lastValueFrom(
        this.sacService.getdetailAttetion(idAttetion)
      );
      if(detailAttetion.status === 'success'){
        return SacActions.getDetailAttentionSuccess({ detailAttetion  });
      }else{
        return SacActions.getDetailAttentionFail();
      }
    } catch (e) {
      console.error(e);
      return SacActions.getDetailAttentionFail();
    }
  }),
  tap(() => this.sacFacade.setLoading(false))
)
);

readonly getNotesFiles$ = createEffect(() =>
this.actions$.pipe(
  ofType(SacActions.getNotesFiles),
  tap(() => this.sacFacade.setLoading(true)),
  switchMap(async ({idNotesFiles}) => {
    try {
      const notesFiles = await lastValueFrom(
        this.sacService.getNotesFiles(idNotesFiles)
      );
      if(notesFiles.status === 'success'){
        return SacActions.getNotesFilesSuccess({ notesFiles  });
      }else{
        return SacActions.getNotesFilesFail();
      }
    } catch (e) {
      console.error(e);
      return SacActions.getNotesFilesFail();
    }
  }),
  tap(() => this.sacFacade.setLoading(false))
)
);

  constructor(
    private readonly actions$: Actions,
    private readonly sacFacade: SacFacade,
    private readonly sacService: SacService,
    private readonly router: Router,
    private readonly alertService: AlertService,
  ) {}
}
